import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API, FILE_UPLOAD } from '@configs/api-endpoints';
import { Store } from '@ngxs/store';
import { UploadedFileResponse } from '@shared/models/uploaded-file.model';
import { Observable, catchError, tap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  private authToken: string | null = null;

  constructor(
    private http: HttpClient,
    private store: Store
  ) {
    const state = this.store.selectSnapshot(state => state.auth);
    this.authToken = state ? state.token : null;
  }

  uploadSingleFile(orgId: string, paths: string[], file: File, heading?: string): Observable<UploadedFileResponse> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    paths.forEach(path => formData.append('path', path));
    if (heading) {
      formData.append('heading', heading);
    }

    const URL = `${FILE_UPLOAD}/upload-to-path/${orgId}`;
    console.log('URL', URL);

    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authToken}`,
    });

    console.log('formData', formData);

    return this.http.post<UploadedFileResponse>(URL, formData, { headers });
  }

  processBulkOrderFile(file: File, orgId: string, operationId: string): Observable<unknown> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('org_id', orgId);
    formData.append('operation_id', operationId);

    const URL = `${API.fileParser.processBulkOrders}`;

    formData.forEach((value, key) => {
      console.log(key, value);
    });

    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authToken}`,
    });

    return this.http.post<unknown>(URL, formData, { headers }).pipe(
      tap(response => console.log('Server response:', response)),
      catchError(error => {
        console.error('Error processing bulk order file:', error);
        console.error('Error response:', error.error);
        return throwError(() => new Error('Error processing bulk order file'));
      })
    );
  }
}
